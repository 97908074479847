<template>
  <v-app-bar
    app
    flat
    outlined
    :height="showBanner ? 120 : 60"
    :value="showHeader"
    class="Header"
  >
    <v-container fluid class="Header__container">
      <Banner v-if="showBanner" />
      <v-row justify="space-between" class="my-2">
        <div class="d-flex align-center">
          <p class="Header__page-name">{{ pageName }}</p>
        </div>
        <v-menu offset-y left>
          <template #activator="{ on }">
            <v-btn v-on="on" icon x-large>
              <v-avatar color="#e3e5ec" size="40" />
            </v-btn>
          </template>
          <v-list outlined dense class="Header_menu" width="250">
            <v-list-item to="/settings">
              <v-list-item-icon>
                <v-icon size="20">mdi-settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="SideBar__nav-menu-link-label">
                  Settings
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  <a @click="signOut"> Sign Out </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Banner from '@/components/banner/banner.vue';

const { mapActions: authActions } = createNamespacedHelpers('auth');
const { mapGetters: sharedGetters } = createNamespacedHelpers('shared');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'Header',
  components: {
    Banner,
  },
  props: {
    pageName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...sharedGetters({
      showHeader: 'SHOW_HEADER',
    }),
    ...customerGetters({
      user: 'USER',
    }),
    showBanner() {
      if (
        this.user?.enabled2FA !== null &&
        this.user?.enabled2FA !== undefined
      ) {
        return !this.user?.enabled2FA;
      }
      return false;
      // return !this.user?.enabled2FA;
    },
  },
  methods: {
    ...authActions(['signOut']),
  },
};
</script>

<style lang="scss" scoped>
@import './header';
</style>
