<template>
  <div class="CustomerPicker">
    <v-card flat light tile v-if="!collapsed" class="mx-4">
      <v-card-text>
        <v-select
          item-text="CustomerName"
          item-value="customer_id"
          persistent-hint
          return-object
          single-line
          outlined
          dense
          :value="selected_customer"
          :items="customers"
          @change="handleSelectedCustomer"
        />
      </v-card-text>
    </v-card>
    <v-menu
      right
      v-else
      :close-on-content-click="false"
      v-model="menu"
      offset-x
      nudge-right="10"
    >
      <template #activator="{ on }">
        <v-avatar
          size="40"
          color="#fff"
          tile
          class="mx-2 CustomerPicker__name-avatar"
          v-on="on"
        >
          {{ selectedCustomerShortned }}
        </v-avatar>
      </template>

      <v-card flat light tile class="CustomerPicker__customer-selector">
        <v-card-text>
          <v-select
            item-text="CustomerName"
            item-value="customer_id"
            persistent-hint
            return-object
            single-line
            outlined
            dense
            :value="selected_customer"
            :items="customers"
            @change="handleSelectedCustomer"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapGetters: customerGetters,
  mapActions: customerActions,
  mapMutations: customerMutations,
} = createNamespacedHelpers('customer');

export default {
  name: 'CustomerPicker',
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  watch: {
    customer() {
      if (this.isShAdmin) {
        this.fetchAllCustomers();
      }
    },
    collapsed() {
      this.menu = false;
    },
  },
  created() {
    const customer_id = this.$route.query.customer_id;
    if (customer_id !== null && customer_id !== undefined) {
      this.setSelectedCustomer({ customer_id: Number(customer_id) });
    }
  },
  async mounted() {
    if (this.isShAdmin) {
      const customer_id = this.$route.query.customer_id;
      await this.fetchAllCustomers();
      const customer = this.customers.find((c) => {
        return c.customer_id === Number(customer_id);
      });
      if (customer) {
        this.setSelectedCustomer(customer);
      }
    }
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      customers: 'CUSTOMERS',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    isShAdmin() {
      return this.customer && this.customer.customer_id === 0;
    },
    selectedCustomerShortned() {
      return (this.selected_customer.CustomerName || '')
        .split(' ')
        .reduce((prev, curr) => {
          prev += curr[0]?.toUpperCase();

          return prev;
        }, '');
    },
  },
  methods: {
    ...customerActions(['fetchAllCustomers']),
    ...customerMutations({
      setSelectedCustomer: 'SET_SELECTED_CUSTOMER',
    }),
    handleSelectedCustomer(value) {
      this.setSelectedCustomer(value);
      this.menu = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import './customer-picker';
</style>
