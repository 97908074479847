<template>
  <div class="Navigation">
    <nav-header :page-name="pageName" />
    <RefreshToken />
    <sidebar />
    <v-main class="Navigation__main-content">
      <v-container fluid class="Navigation__container">
        <router-view v-if="customer && !customerDataLoading" />
        <div
          v-if="customerDataLoading && !customer"
          class="Navigation__main-content-loading"
        >
          <Loader />
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Sidebar from '@/components/sidebar/sidebar.vue';
import NavHeader from '@/components/header/header.vue';
import Loader from '@/components/loader/loader.vue';
import RefreshToken from '@/components/refresh-token/refresh-token.vue';

const { mapActions: customerActions, mapGetters: customerGetters } =
  createNamespacedHelpers('customer');

export default {
  name: 'Navigation',
  components: {
    Sidebar,
    NavHeader,
    Loader,
    RefreshToken,
  },
  computed: {
    ...customerGetters({
      user: 'USER',
      customer: 'CUSTOMER',
      customerDataLoading: 'CUSTOMER_DATA_LOADING',
      featureFlags: 'FEATURE_FLAGS',
    }),
    pageName() {
      return this.$route.name;
    },
  },
  methods: {
    ...customerActions([
      'getCustomerData',
      'getLoggedInUser',
      'getFeatureFlags',
    ]),
  },
  async created() {
    await this.getCustomerData();
    this.getLoggedInUser();
    this.getFeatureFlags();
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.Navigation {
  &__container {
    padding: 0 $navigation-padding;
  }
}
</style>
