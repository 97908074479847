<template>
  <v-banner single-line class="border-none" tile>
    <v-icon slot="icon" color="warning" size="24"> mdi-information </v-icon>
    Activate Two Factor Authentication on your account.
    <template v-slot:actions>
      <v-btn color="primary" text @click="gotoSettingsPage"> Settings </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: 'Banner',
  methods: {
    gotoSettingsPage() {
      this.$router.push({ path: '/settings' });
    },
  },
};
</script>

<style lang="scss" scoped>
.border-none {
  border-bottom: 0px;
}
</style>
