<template>
  <div class="d-flex">
    <v-list-item
      :to="path"
      class="SideBar__nav-menu-link"
      :class="{ 'has-path': path, 'v-list-item--active': isActive }"
    >
      <v-list-item-icon class="SideBar__nav-menu-link-icon-container">
        <v-icon size="20">{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="SideBar__nav-menu-link-label">
          {{ label }}
        </v-list-item-title>
      </v-list-item-content>

      <div class="d-flex justify-center">
        <slot></slot>
      </div>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'LinkItem',
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: null,
    },
  },
  computed: {
    isActive() {
      if (this.path === '/' && this.$route.path === '/') {
        return true;
      } else if (this.path !== '/') {
        return this.path && this.$route.path.startsWith(this.path);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './sidebar';

.SideBar__nav-menu-link {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.has-path {
    cursor: pointer;
  }
}
</style>
