<template>
  <v-dialog v-model="showRefreshTokenModal" :max-width="500" :persistent="true">
    <v-card outlined class="ActionPrompt__card">
      <v-card-title class="ActionPrompt__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            <p class="ma-0">Idle Session Expiry</p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ActionPrompt__content">
        <p>
          {{ getBody }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import moment from 'moment';

import { createNamespacedHelpers } from 'vuex';
const { mapActions: authActions } = createNamespacedHelpers('auth');

export default {
  name: 'RefreshToken',
  data() {
    return {
      showRefreshTokenModal: false,
      waitForResponse: null,
      refreshTokenTimerId: null,
      timeTillModalCloses: 0,
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'],
      lastActionTime: null,
      unfocusTime: null,
    };
  },
  computed: {
    getBody() {
      return `Your have been inactive for ${this.getTime}. Perform an action to stay logged in.`;
    },
    getTime() {
      if (!this.lastActionTime) return 'a while';

      let timeString = moment(new Date(this.lastActionTime)).fromNow();
      timeString = timeString.replace(' ago', '');
      return timeString;
    },
  },
  created() {
    this.startRefreshTimer();
    this.events.forEach((eventItem) => {
      window.addEventListener(eventItem, this.updateLastActionTime, this);
    });

    window.addEventListener('visibilitychange', this.toggleTimer);
  },
  beforeDestroy() {
    this.clearTimers();
    this.events.forEach((eventItem) => {
      window.removeEventListener(eventItem, this.updateLastActionTime, this);
    });

    window.removeEventListener('visibilitychange', this.toggleTimer);
  },
  methods: {
    ...authActions(['signOut', 'refreshToken']),
    showModal() {
      // if idle time is more than 5mins, prompt for action.
      const fiveMins = 5 * 60 * 1000;
      if (Date.now() - this.lastActionTime > fiveMins) {
        this.showRefreshTokenModal = true;
        this.waitForResponse = setTimeout(() => {
          this.close();
        }, this.timeTillModalCloses);
      } else {
        this.closeDialog();
        this.refresh();
      }
    },
    updateLastActionTime() {
      this.lastActionTime = Date.now();

      if (this.showRefreshTokenModal) {
        this.closeDialog();
        this.refresh();
      }
    },
    closeDialog() {
      if (this.waitForResponse) {
        clearTimeout(this.waitForResponse);
      }
      this.showRefreshTokenModal = false;
    },
    close() {
      this.signOut();
      this.closeDialog();
    },
    decodeJWT() {
      try {
        const decoded = decode(Cookies.get('token'));
        const currentTime = Math.floor(Date.now() / 1000);
        return decoded.exp - currentTime - 20; // extra 20s for lag
      } catch {
        // return zero to show expired tokne;
        return 0;
      }
    },
    startRefreshTimer() {
      const timeBeforeExpiry = this.decodeJWT();
      const timeForPrompt = 5 * 60; // if token expires in less than 5 mins
      const promptAfter = timeBeforeExpiry - timeForPrompt;

      if (timeBeforeExpiry - timeForPrompt > 0) {
        this.refreshTokenTimerId = setTimeout(() => {
          this.timeTillModalCloses = timeForPrompt * 1000;
          this.showModal();
        }, promptAfter * 1000);
      } else {
        this.timeTillModalCloses = timeBeforeExpiry * 1000;
        this.showModal();
      }
    },
    clearTimers() {
      if (this.waitForResponse) {
        clearTimeout(this.waitForResponse);
      }
      if (this.refreshTokenTimerId) {
        clearTimeout(this.refreshTokenTimerId);
      }
    },
    toggleTimer() {
      const status = document.visibilityState;
      if (status === 'visible') {
        this.startRefreshTimer();
      } else {
        this.clearTimers();
      }
    },
    async refresh() {
      try {
        await this.refreshToken();
        this.clearTimers();
        this.startRefreshTimer();
      } catch (e) {
        this.close();
        this.clearTimers();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
